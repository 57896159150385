import { Environment } from '../../../environment.interface';

export const environment: Environment = {
  production: true,
  authApi: 'ozd',
  scope: 'admin',
  platform: 'web',

  appVersion: '1.2.5',

  apiBaseUrl: 'https://api.uat.ee.acal.ma/ee/api',
  imageBaseUrl: 'https://api.uat.ee.acal.ma/ee/api/v2/public/file',
  socketIoUrl: 'https://api.uat.ee.acal.ma',

  apiPageSize: 10,

  jwt: { refreshTokenNonce: 'TzNYYsG1eo' },

  publicUrl: 'adm.uat.ee.acal.ma',

  /** Used for Audo Playback as well */
  voiceRecording: {
    maxDurationInMs: 20000, // Unused in Web mode
    tickIntervalInMs: 200,
  },
};
